import React from "react";

const CaseStudies = () => {
  return (
    <div className='page'>
      <div className='container'>
        <div className='row'>
          <h3>1st month:  Linux & Git<br/>
              2nd month:  AWS<br/>
              3rd month:  Terraform<br/>
              4th month:  Docker & Jenkins<br/>
              5th month:  Kubernetes<br/>
              6th month:  Project & Soft Skills
          
          
          </h3>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;

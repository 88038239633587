import React from "react";

const About = () => {
  return (
    <div className='page'>
      <div className='container'>
        <div className='row'>
          <h3>This page is under construction</h3>

            {/* <form name="contact" method="POST" data-netlify="true">
              <p>
                <label>Full Name <input type="text" name="name" /></label>
              </p>
              <p>
                <label>Phone <input type="number" name="email" /></label>
              </p>
              <p>
                <label>Email <input type="email" name="email" /></label>
              </p>
              <p>
                <label>Comment <textarea type="text" name="comments" /></label>
              </p>
              <p>
                <button type="submit">Send</button>
              </p>
            </form> */}


        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import { ReactComponent as CasesNext } from "../assets/arrow-right.svg";
import { ReactComponent as CasesPrev } from "../assets/arrow-left.svg";
import VideoComponent1 from "./video-1";
import VideoComponent2 from "./video-2";

// const caseStudies = [
//   {
//     id: 1,
//     subtitle: "Curology",
//     title: "A custom formula for your skin’s unique needs",
//     img: "curology-min"
//   },
//   {
//     id: 2,
//     subtitle: "Yourspace",
//     title: "Open space floor plans for you next venture",
//     img: "yourspace-min"
//   },
//   {
//     id: 3,
//     subtitle: "Lumin",
//     title: "For your best look ever",
//     img: "lumin-min"
//   }
// ];

const Cases = () => {
  return (
    <section className='cases'>
      <div className='container-fluid'>
        <div className='cases-navigation'>
          <div className='cases-arrow prev disabled'>
            <CasesPrev />
          </div>
          <div className='cases-arrow next'>
            <CasesNext />
          </div>
        </div>
        <div className='row'>

            <div className='case' >
              <div className='case-details'>
                <span></span>
                <h2> </h2>
              </div>
              <div className='case-image'>

                <VideoComponent1/>

              </div>
            </div>

            <div className='case' >
              <div className='case-details'>
                <span>Get a career, not a job!</span>
                <h2>| just in 180 days.. become a DevOps Engineer |</h2>
              </div>
              <div className='case-image'>

                <img
                  src={require(`../assets/yourspace-min.png`)}
                  alt=""
                />
                
              </div>
            </div>

            <div className='case' >
              <div className='case-details'>
                <span></span>
                <h2> </h2>
              </div>
              <div className='case-image'>

                <VideoComponent2/>
                
              </div>
            </div>
 
        </div>
      </div>
    </section>
  );
};

export default Cases;


import React from "react";
import MyVideo from "../assets/1.mp4";

class VideoComponent1 extends React.Component {
  render() {
    return (
      <video autoPlay playsInline loop muted width="100%" height="100%" objectFit="cover">
        <source src={MyVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    );
  }
}

export default VideoComponent1